import React, { useContext } from 'react';
import styled from "styled-components";
// Context
import { ContentContext } from '../../lib/Content';

export default function Goals() {
  const { content } = useContext(ContentContext);
  const team = content.team;
  return (
    <GoalsWrapper>
      <div id="goals" className="intro">
        <h2>{team.title}</h2>
      </div>
      <div className="goals-container">
        <div className="goal ">
          <img src={team.firstTeamImage.url} alt={team.firstTeamImage.name} />
          <div className="info">
            <p className="title">{team.firstTeamTitle}</p>
            <p className="text">
              {team.firstTeamText}
            </p>
          </div>
        </div>
        <div className="goal color">
          <img src={team.secondTeamImage.url} alt={team.secondTeamImage.name} />
          <div className="info">
            <p className="title">{team.secondTeamTitle}</p>
            <p className="text">
              {team.secondTeamText}
            </p>
          </div>
        </div>
        <div className="goal ">
          <img src={team.thirdTeamImage.url} alt={team.thirdTeamImage.name} />
          <div className="info">
            <p className="title">{team.thirdTeamTitle}</p>
            <p className="text">
              {team.thirdTeamText}
            </p>
          </div>
        </div>
      </div>
    </GoalsWrapper>
  )
}

const GoalsWrapper = styled.section`
  background: #F8F6F0;
  padding: 6rem 2rem 0;

  .goals-container {
    margin-top: 4rem;
    display: flex;

    @media(max-width: 730px) {
      flex-direction: column;
      margin-top: 10rem;
    }

    .goal {
      width: 33.33%;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      background: #DAD1BD;

      @media(max-width: 730px) {
        width: 100%;

        &:not(:last-child) {
          margin-bottom: 4rem;
        }
      }

      &:nth-child(2) {
        background: #E6DDC9;
      }

      img {
        margin-top: 3rem;
        margin-bottom: 2rem;
        height: 8rem;
      }

      .info {
        width: 90%;
        margin-top: 1rem;
        padding-bottom: 3rem;
        padding-left: 1.5rem;

        @media(max-width: 900px) {
          top: 56%;
        }


        p {
          width: 100%;

          &.title {
            font-size: 2.3rem;
            padding-right: 1rem;
            font-weight: bolder;
          }

          &.text {
            width: 92%;
            font-size: 1.4rem;
            margin-top: 2rem;
            font-family: 'Lato';
            font-weight: bolder;
            line-height: 2.2rem;
          }
        }
      }
    }
  }

  .intro {
    text-align: center;
    color: #2F2C26;

    h2 {
      font-size: 3rem;
      width: 30%;
      margin: 0 auto;

      @media(max-width: 820px) {
        width: 40%;
      }

      @media(max-width: 560px) {
        width: 75%;
      }
    }

    p.subtitle {
      margin-top: 3rem;
      font-family: 'Lato';
    }
  }
`;
