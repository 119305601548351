// React
import React, { useState, useEffect } from 'react';
import styled from "styled-components";
// Componentes
import Home from '../components/SecondLanding/Home';
import GetInvolved from '../components/SecondLanding/GetInvolved';
import Features from '../components/SecondLanding/Features';
import Disclaimer from '../components/SecondLanding/Disclaimer';
import Footer from '../components/Footer';
// Location hook
import {
  useLocation,
  Redirect
} from "react-router-dom";
// firebase
import { db } from "../lib/Firebase";

function Loading() {
  return (
    <div
      style={{
        display: "flex",
        height: "96vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img src={require("../assets/images/logo.svg")} alt="Consciour Crops" />
    </div>
  )
}

export default function SecondLanding() {
  let location = useLocation();
  const [linkDisabled, setLinkDisabled] = useState(null);
  const [prospect, setProspect] = useState(null);
  // const [errorA, setErrorA] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const hash = location.search.substring(5);

    db.collection('prospects').where('hash', '==', hash).limit(1).get().then(value => {
      const prospectObj = value.docs[0].data();
      setProspect(prospectObj);
      setLinkDisabled(prospectObj.linkDisabled);
      setLoading(false);
    }).catch(err => {
      console.error(err)
      setProspect(null);
      setLinkDisabled(true);
      setLoading(false);
    })
  }, [location]);

  return loading ?
    <Loading />
    : !linkDisabled ? (
      <LandingWrapper>
        <Disclaimer />
        <Home />
        <Features />
        <GetInvolved prospect={prospect} />
        <Footer />
      </LandingWrapper>
    )
      :
      (<Redirect to={"page-not-found"} />);
}

const LandingWrapper = styled.div`

`;
