import React, { useState, useEffect } from 'react';
import { db } from "./Firebase";

export const ContentContext = React.createContext();

export const ContentProvider = ({ children }) => {
  const [content, setContent] = useState(null);

  useEffect(() => {
    db.collection('content').doc('KuCDaPDjY1y8BnNEUPW1').onSnapshot({
        next: querySnapshot => {
            const content = querySnapshot.data();
            setContent(content);
        }
    })
  }, [setContent]);

  return (
    <ContentContext.Provider
      value={{ content }}
    >
      {children}
    </ContentContext.Provider>
  )
}