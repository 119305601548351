import React, { useContext } from 'react';
import styled from "styled-components";
// Context
import { ContentContext } from '../../lib/Content';

export default function Features() {
  const { content } = useContext(ContentContext);
  const informationItems = content.informationItems;
  return (
    <FeaturesWrapper>
      <div className="feature-container">
        <div className="feature">
          <div className="container">
            <img src={informationItems.firstInformationItemImage.url} alt={informationItems.firstInformationItemImage.name} />
            <div className="info">
              <p className="title">{informationItems.firstInformationItemTitle}</p>
              <p className="text">
                {informationItems.firstInformationItemText}
              </p>
            </div>
          </div>
        </div>
        <div className="feature coin">
          <div className="container">
            <img src={informationItems.secondInformationItemImage.url} alt={informationItems.secondInformationItemImage.name} />
            <div className="info">
              <p className="title">{informationItems.secondInformationItemTitle}</p>
              <p className="text">
                {informationItems.secondInformationItemText}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="feature-container">
        <div className="feature">
          <div className="container">
            <img src={informationItems.thirdInformationItemImage.url} alt={informationItems.thirdInformationItemImage.name} />
            <div className="info">
              <p className="title">{informationItems.thirdInformationItemTitle}</p>
              <p className="text">
                {informationItems.thirdInformationItemText}
              </p>
            </div>
          </div>
        </div>
        <div className="feature taxes">
          <div className="container">
            <img src={informationItems.fourthInformationItemImage.url} alt={informationItems.fourthInformationItemImage.name} />
            <div className="info">
              <p className="title">{informationItems.fourthInformationItemTitle}</p>
              <p className="text">
                {informationItems.fourthInformationItemText}
              </p>
            </div>
          </div>
        </div>
      </div>
    </FeaturesWrapper>
  )
}

const FeaturesWrapper = styled.section`
  margin: 4rem 12rem;

  @media(max-width: 600px) {
    margin: 4rem 6rem;
  }

  .feature-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;

    @media(max-width: 1000px) {
      flex-direction: column;
      align-items: center;
      margin-top: 0;
    }

    .feature {
      background: #E6DDC9;
      padding: 1rem 2rem;
      display: flex;
      width: 44%;
      align-items: center;

      @media(max-width: 1000px) {
        margin-bottom: 2rem;
        width: 100%;

        img {
          height: 10rem !important;
        }
      }

      @media(max-width: 600px) {
        margin-bottom: 6rem;

        img {
          margin-right: 0 !important;
        }
      }

      .container {
        align-items: center;
        display: flex;
        transform: translateX(-6rem);
        margin-right: -6rem;

        @media(max-width: 1000px) {
          margin-right: 0;
          transform: translateX(0);
        }

        @media(max-width: 600px) {
          flex-direction: column;
          align-items: flex-start;
          width: 100%;
        }

        img {
          height: 16rem;
          margin-right: 2rem;

          @media(max-width: 600px) {
            align-self: flex-end;
            margin-bottom: 1rem;
            transform: translateY(-4rem);
          }
        }

        .info {

          @media(max-width: 600px) {
            margin-top: -4rem;
          }

          p.title {
            margin-bottom: 2.2rem;
            font-weight: bolder;
            font-size: 2.2rem;
          }

          p.text {
            font-size: 1.6rem;
            line-height: 1.8rem;
            font-family: 'Lato';
            margin-bottom: 1rem;
          }
        }
      }

      &.coin img {
        height: 12rem;
      }

      &.taxes img {
        height: 12rem;
      }

    }
  }
`;
