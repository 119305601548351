import React, { useState, useContext } from 'react';
import styled from "styled-components";
import DateTimePicker from 'react-datetime-picker';
import axios from 'axios';
import * as moment from 'moment';
// Context
import { ContentContext } from '../../lib/Content';

export default function GetInvolved({ prospect }) {
  const [date, setDate] = useState(null);
  const { content } = useContext(ContentContext);
  const [showNotify, setShowNotify] = useState(false);
  const getInvolvedFurtherInformation = content.getInvolvedFurtherInformation;


  const handleRequestDate = () => {
    const data = {
      firstName: prospect.firstName,
      lastName: prospect.lastName,
      investor: prospect.investorType,
      location: prospect.location,
      phone: prospect.phone,
      date: moment(date).format('MM/DD/YYYY'),
      hour: moment(date).format('LT')
    };

    axios.post(
      'https://us-central1-consciouscrops-40375.cloudfunctions.net/sendDateRequestEmail',
      {
        data
      }
    ).then(res => {
      setShowNotify('Thanks! We have received your date request.');
      setTimeout(() => setShowNotify(false), 5000);
    }).catch(err => {
      setShowNotify('An error has ocurred');
      setTimeout(() => setShowNotify(false), 5000);
    })

    console.log(data);
  };

  return (
    <GetInvolvedWrapper>
      <div id="getInvolved" className="orange-box">
        <div className="info">
          <p className="title">{getInvolvedFurtherInformation.title}</p>
          <p className="text">{getInvolvedFurtherInformation.text}</p>
        </div>
        <div className="date-container">
          <label>Pick a date</label>
          <DateTimePicker
            value={date}
            onChange={date => setDate(date)}
            className="input"
            format="MM/dd/y hh:mm a"
            disableClock={true}
          />
          <div className="button">
            <button onClick={handleRequestDate} disabled={date === null ? true : false}>{getInvolvedFurtherInformation.buttonText}</button>
          </div>
        </div>
      </div>

      <div className="forest-container">
        <img className="backtree" src={getInvolvedFurtherInformation.backgroundImage.url} alt={getInvolvedFurtherInformation.backgroundImage.name} />
      </div>

      <div className={`notify ${showNotify ? 'show' : ''}`}>
        {showNotify}
      </div>
    </GetInvolvedWrapper>
  )
}

const GetInvolvedWrapper = styled.section`
  background: #F8F6F0;
  margin-top: 10rem;

  @media(max-width: 700px) {
    margin-top: 5rem;
  }

  @media(max-width: 550px) {
    overflow: hidden;
  }

  .notify {
    position: fixed;
    bottom: 4rem;
    right: 4rem;
    background: #E6DDC9;
    padding: 2rem 4rem;
    border-bottom: 2px solid black;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    transform: scale(0);
    transition: 0.4s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    z-index: 90;

    &.show {
      transform: scale(1);
    }
  }

  .forest-container {
    position: relative;
    margin-top: -13rem;

    @media(max-width: 620px) {
      margin-top: -14rem;
    }

    img.backtree {
      width: 100%;
      height: 18rem;
      object-fit: fill;
      
      @media(max-width: 550px) {
        height: 20rem;
        object-fit: cover;
      }
    }
  }

  .orange-box {
    background: #F3C256;
    margin: 0 8rem;
    padding: 4rem 7rem 14rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .date-container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      width: 30%;

      @media(max-width: 700px) {
        width: 100%;
      }

      label {
        align-self: flex-start;
        font-weight: bold;
        margin-bottom: 0.8rem;
      }
    }
    
    @media(max-width: 700px) {
      flex-direction: column;
      align-items: flex-start;
      padding: 2rem 3rem 12rem;
    }
    
    @media(max-width: 500px) {
      margin: 0 2rem;
    }

    .info {
      width: 45%;
      color: black;
      z-index: 30;

      @media(max-width: 700px) {
        width: 100%;
        margin-bottom: 2rem;
      }

      p.title {
        font-size: 3.5rem;
        font-weight: bolder;
      }

      p.text {
        margin-top: 1.2rem;
        font-size: 1.8rem;
        font-family: 'Lato';
      }
    }

    .button {
      button {
        background: #2F2C26;
        color: #ffffff;
        border: none;
        font-family: 'Lato';
        padding: 1rem 2rem;
        font-size: 1.4rem;
        cursor: pointer;
        transition: 0.2s;

        &:hover {
          opacity: 0.9;
        }

        &:disabled {
          background: #979797;
          cursor: not-allowed;
        }
      }
    }
  }

  .input {
    width: 100%;
    background: rgba(255, 255, 255, 0.6);
    padding: 1rem 0.7rem;
    border: none;
    border-bottom: 2px solid black;
    font-family: 'Lato';
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }

  .errorMessage {
    font-size: 1rem;
    margin-top: 0.4rem;
    color: red;
  }

  .react-datetime-picker__wrapper {
    border: none;
  }
`;
