import React, { useContext } from "react";
import styled from "styled-components";
// Context
import { ContentContext } from "../../lib/Content";

export default function Challenge() {
  const { content } = useContext(ContentContext);
  const challenge = content.challenge;
  return (
    <ChallengeWrapper bottomImage={challenge.bottomImage}>
      <div id={"challenge"} className="text-orange">
        <div className="text-info">
          <p className="title">{challenge.title}</p>
          <p className="text">{challenge.textUnderTitle}</p>
        </div>
        <img
          src={challenge.imageBesideTitle.url}
          alt={challenge.imageBesideTitle.name}
        />
      </div>
      <div className="second-section">
        <div className="blue-box">
          <div className="left">
            <p className="title">{challenge.blueBoxTitle}</p>
          </div>
          <div className="right">
            <p className="text bold">{challenge.blueBoxText}</p>
          </div>
        </div>
      </div>
      <div className="farm-box">
        <p>{challenge.bottomText}</p>
      </div>
    </ChallengeWrapper>
  );
}

const ChallengeWrapper = styled.section`
  background: #f8f6f0;

  .second-section {
    padding: 4rem 10rem 0 10rem;

    @media (max-width: 500px) {
      padding: 2rem 2rem 0 2rem;
    }

    .blue-box {
      background: #a3c9ed;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      color: white;
      padding: 3rem 8rem 8rem;

      @media (max-width: 800px) {
        flex-direction: column;
        padding: 3rem 5rem 8rem;
      }

      p.title {
        font-size: 3rem;
        font-weight: bolder;
        margin-bottom: 1.5rem;
      }

      p.text {
        font-family: "Lato";
        font-size: 1.7rem;

        &.bold {
          font-weight: bolder;
        }
      }

      .left {
        width: 100%;
        padding-right: 10%;

        @media (max-width: 1000px) {
          padding-right: 15%;
        }
      }
    }
  }

  .farm-box {
    width: 100%;
    background-image: url(${(props) => props.bottomImage.url});
    background-size: cover;
    padding: 18rem 0 4rem 0;
    margin-top: -7rem;

    p {
      color: white;
      width: 40%;
      text-align: center;
      margin: 0 auto;
      font-family: "Lato";
      font-weight: 900;

      @media (max-width: 500px) {
        width: 80%;
      }
    }
  }

  .text-orange {
    padding: 8rem 10rem 2rem 10rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 800px) {
      flex-direction: column;
    }

    @media (max-width: 500px) {
      padding: 4rem 4rem 0 4rem;
    }

    .text-info {
      width: 31%;

      @media (max-width: 1000px) {
        width: 40%;
      }

      @media (max-width: 800px) {
        width: 100%;
        margin-bottom: 3rem;
      }

      p.title {
        font-weight: bolder;
        font-size: 4rem;
      }

      p.text {
        font-size: 1.7rem;
        margin-top: 2rem;
        font-family: "Lato";

        &:nth-child(2) {
          font-weight: bolder;
        }
      }
    }

    img {
      width: 40%;
      margin-right: 15%;

      @media (max-width: 1000px) {
        margin-right: 8%;
      }

      @media (max-width: 800px) {
        width: 20rem;
      }
    }
  }
`;
