// React
import React, { useState, useContext } from 'react';
import styled from "styled-components";
import { useForm } from "react-hook-form";
import axios from 'axios';
import { ReCaptcha } from 'react-recaptcha-v3'
// Componentes and Others
import { countries } from '../lib/Countries';
import { usaStates } from '../lib/UsaStates';
import ContactFormService from '../services/ContactFormService';
import { emailPattern, phonePattern } from '../lib/Patterns';
// Context
import { ContentContext } from '../lib/Content';

export default function ContactForm() {
  const { handleSubmit, register, errors, reset } = useForm();
  const [recaptchaVerify, setRecaptchaVerify] = useState(false);
  const [locationOptions, setLocationOptions] = useState('');
  const [showNotify, setShowNotify] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const { content } = useContext(ContentContext);
  const contact = content.contact;

  const contactFormService = new ContactFormService();

  const onSubmit = values => {
    values.status = 'pending'// Pending default value when sending the form
    const date = new Date(); // Today
    const dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: '2-digit', day: '2-digit' }); // The format that i need
    const [{ value: month },,{ value: day },,{ value: year }] = dateTimeFormat.formatToParts(date);
    values.date = `${month}/${day}/${year}` // US date format
    reset();
    if (recaptchaVerify) {
      contactFormService.sendForm(values)
        .then(r => {
          if (r) {
              setShowNotify('Thanks! We have received your contact information.');
              setTimeout(() => setShowNotify(false), 5000);
              sendEmail(`${values.firstName} ${values.lastName}`, values.email);
          } else {
            setShowNotify('Information already sent.');
            setTimeout(() => setShowNotify(false), 5000);
          }
        })
        .catch(err => {
          setShowNotify('An error has ocurred');
          setTimeout(() => setShowNotify(false), 5000);
          console.log(err);
        });
    } else {
      setShowNotify('An error has ocurred');
      setTimeout(() => setShowNotify(false), 5000);
    }
  };

  const sendEmail = (name, email) => {
    axios.post(
      'https://us-central1-consciouscrops-40375.cloudfunctions.net/sendWelcomeEmail',
      {
        data: {
          name,
          email
        }
      }
    )
  }

  const verifyCallback = (res) => {
    setRecaptchaVerify(true);
  };

  const changeInvestor = (value) => {
    if (value === 'Not a US person') {
      setLocationOptions('countries');
    } else {
      setLocationOptions('states');
    }
  };

  return (
    <ContactFormWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* INVESTOR TYPE */}
        <div className="input-group input-group-investor">
          <label htmlFor="investorType">
            {contact.firstDropdownTitle}
            <img src={require('../assets/images/info.png')} alt="info" onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)} />
            <div className={`tooltip ${showTooltip ? 'visible' : ''}`}>
              A Bank (3(a)(2) Securities Act), registered broker or dealer ( Sect. 15 of the Securities Exchange Act 1934);  insurance company (2(a)(13) of the Securities Act); registered investment company  or business development company (Investment Company Act of 1940); business development company; licensed Small Business Investment Company (section 301(c) or (d) of the Small Business Investment Act of 1958); any employee with total assets in excess of $5,000,000, within or outside of ERISA
              <br /><br />
              A private business development company (section 202(a)(22) of the Investment Advisers Act of 1940)
              <br /><br />
              An organization (501(c)(3) of the IRC), corporation, Massachusetts or similar business trust, or partnership, with total assets in excess of $5,000,000
              <br /><br />
              A natural person whose individual net worth, or joint net worth with that person's spouse, exceeds $1,000,000, excluding primary residence, or any debt secured by same
              <br /><br />
              A natural person with individual income in excess of $200,000 or joint income with spouse in excess of $300,000 for past 2 years, and with reasonable expectation of reaching the same income level in the current year
              <br /><br />
              A trust, with total assets in excess of $5,000,000, directed by a “sophisticated person” as described in §230.506(b)(2)(ii) of Regulation D promulgated by the SEC under the Securities Act
              <br /><br />
              An entity in which all of the equity owners are accredited investors
              <br /><br />
              I am not a US person (902 of Regulation S, 1933 Act)
            </div>
          </label>
          <select defaultValue={''} id="investorType" name="investorType" ref={register({ required: 'Required', validate: v => v !== '' || 'Required' })} onChange={e => changeInvestor(e.target.value)}>
            <option value='' disabled hidden>Select investor type</option>
            {contact.firstDropdownOptions.map((value, index) =>
                <option value={value} key={index}>{value}</option>
            )}
            {/* <option value="Bank, registered broker or dealer">Bank, registered broker or dealer</option>
            <option value="Private business development company">Private business development company</option>
            <option value="Organization, corporation, business trust or partnership with total assets in excess of $5,000,000">Organization, corporation, business trust or partnership with total assets in excess of $5,000,000</option>
            <option value="Natural person whose individual net worth, or joint net worth exceeds $1,000,000">Natural person whose individual net worth, or joint net worth exceeds $1,000,000</option>
            <option value="Natural person with individual income in excess of $200,000 or joint income of $300,000 for the past 2 years">Natural person with individual income in excess of $200,000 or joint income of $300,000 for the past 2 years</option>
            <option value="Trust, with total assets in excess of $5,000,000">Trust, with total assets in excess of $5,000,000</option>
            <option value="Not a US person">Not a US person</option> */}
          </select>

          <div className="checkbox">
            <input type="checkbox" id="checkInvestor" name="checkInvestor" ref={register({ required: 'Required' })} />
            <label htmlFor="checkInvestor">I certify that the investor category I chose truly and accurately describes me.</label>
          </div>
          <p className="errorMessage">{(errors.checkInvestor && errors.checkInvestor.message) || (errors.investorType && errors.investorType.message)}</p>
        </div>

        {/* FIRST NAME */}
        <div className="input-group">
          <label htmlFor="firstName">{contact.firstInput}</label>
          <input placeholder={contact.firstInput} type="text" name="firstName" id="firstName" ref={register({ required: 'Required' })} />
          <p className="errorMessage">{errors.firstName && errors.firstName.message}</p>
        </div>

        {/* LAST NAME */}
        <div className="input-group">
          <label htmlFor="lastName">{contact.secondInput}</label>
          <input placeholder={contact.secondInput} type="text" id="lastName" name="lastName" ref={register({ required: 'Required' })} />
          <p className="errorMessage">{errors.lastName && errors.lastName.message}</p>
        </div>

        {/* LOCATION */}
        <div className="input-group">
          <label htmlFor="location">{locationOptions === 'countries' ? 'Country' : contact.secondDropdownTitle}</label>
          <select disabled={locationOptions === ''} defaultValue={''} id="location" name="location" ref={register({ required: 'Required', validate: v => v !== '' || 'Required' })}>
            <option value='' disabled hidden>{contact.secondDropdownTitle}</option>
            {locationOptions === 'countries' ? countries.map(country => (
              <option key={country.code} value={country.name}>{country.name}</option>
            )) : usaStates.map(state => (
              <option key={state.code} value={`USA - ${state.name}`}>{state.name}</option>
            ))}
          </select>
          <p className="errorMessage">{errors.location && errors.location.message}</p>
        </div>

        {/* EMAIL */}
        <div className="input-group">
          <label htmlFor="email">{contact.thirdInput}</label>
          <input placeholder={contact.thirdInput} type="text" id="email" name="email" ref={register({ required: 'Required', pattern: { value: emailPattern, message: 'Invalid Email' } })} />
          <p className="errorMessage">{errors.email && errors.email.message}</p>
        </div>

        {/* PHONE */}
        <div className="input-group">
          <label htmlFor="phone">{contact.fourthInput}</label>
          <input placeholder="(+ xx) " type="text" id="phone" name="phone" ref={register({ required: 'Required', pattern: { value: phonePattern, message: 'Invalid Phone' } })} />
          <p className="errorMessage">{errors.phone && errors.phone.message}</p>
        </div>

        {/* RECAPTCHA */}
        <ReCaptcha
          sitekey={'6Ldb0-wUAAAAABahXVzaj_9S7QBmLQxML-e3Cp2Z'}
          action='action_name'
          verifyCallback={verifyCallback}
        />

        {/* BUTTON */}
        <button type="submit" disabled={!recaptchaVerify}>
          Send
        </button>
      </form>

      <div className={`notify ${showNotify ? 'show' : ''}`}>
        {showNotify}
      </div>
    </ContactFormWrapper>
  )
}

const ContactFormWrapper = styled.div`
  width: 100%;
  position: relative;

  .notify {
    position: fixed;
    bottom: 4rem;
    right: 4rem;
    background: #E6DDC9;
    padding: 2rem 4rem;
    border-bottom: 2px solid black;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    transform: scale(0);
    transition: 0.4s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    z-index: 90;

    &.show {
      transform: scale(1);
    }
  }

  form {
    width: 26rem;
    margin: 4rem auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @media(max-width: 500px) {
      width: 88%;
    }

    .input-group {
      width: 100%;
      display: flex;
      flex-direction: column;

      &:not(:last-child) {
        margin-bottom: 2rem;
      }

      label {
        font-size: 1.4rem;
        font-weight: bolder;
        margin-bottom: 0.8rem;
      }

      select {
        width: 100%;
        background: #F5F1E8;
        height: 3.7rem;
        padding: 1rem 0.7rem;
        border: none;
        border-bottom: 2px solid black;
        font-family: 'Lato';
        font-size: 1.2rem;

        &:disabled {
          background: #F5F1E8;
          cursor: not-allowed;
          border-bottom-color: #d6d6d6;
        }
      }

      input {
        width: 100%;
        background: #F5F1E8;
        padding: 1rem 0.7rem;
        border: none;
        border-bottom: 2px solid black;
        font-family: 'Lato';
        font-size: 1.2rem;
      }

      .checkbox {
        margin-top: 1rem;
        display: flex;
        align-items: center;

        input {
          width: 1.6rem;
          height: 1.6rem;
          background: #E4E0D4;
          border: 1px solid #B7B2A2;
        }

        label {
          font-size: 0.9rem;
          margin: 0;
          line-height: 0.8rem;
          margin-left: 0.8rem;
        }
      }

      &.input-group-investor {
        label {
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;

          img {
            cursor: help;
          }

          .tooltip {
            opacity: 0;
            visibility: hidden;
            position: absolute;
            top: 0;
            left: 110%;
            transition: 0.3s;
            background: #E4E0D4;
            width: calc(100%);
            font-size: 1rem;
            padding: 1rem 1.6rem;
            z-index: 50;

            @media(max-width: 800px) {
              top: 3.5rem;
              left: 0;
            }

            &::before {
              content: '';
              display: block;
              position: absolute;
              width: 1.5rem;
              height: 3rem;
              background: #E4E0D4;
              top: 0;
              left: -1rem;
              z-index: -1;
              clip-path: polygon(0 50%, 100% 0, 100% 100%);

              @media(max-width: 800px) {
                width: 3rem;
                height: 1.5rem;
                clip-path: polygon(50% 0, 100% 100%, 0 100%);
                right: 0;
                left: unset;
                top: -0.5rem;
              }
            }

            &.visible {
              opacity: 1;
              visibility: visible;
            }
          }
        }
      }

      .errorMessage {
        font-size: 1rem;
        margin-top: 0.4rem;
        color: red;
      }
    }

    button {
      background: #2F2C26;
      color: #ffffff;
      border: none;
      font-family: 'Lato';
      padding: 1rem 4rem;
      font-size: 1.4rem;
      cursor: pointer;
      transition: 0.2s;

      &:disabled {
        background: #bfbfbe;
        cursor: not-allowed;
      }

      &:hover {
        opacity: 0.7;
      }
    }
  }
`;
