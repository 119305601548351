// React
import React, { useEffect } from 'react';
import styled from "styled-components";
// Componentes
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

export default function PrivacyPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <PrivacyWrapper>
      <Navbar />
      <div className="container">
        <h2>Conscious Crops LLC Website Disclaimer</h2>
        <h2>Terms of Use - Privacy Policy</h2>

        <h4>Legal terms under which access to this web site is made available</h4>
        <p>If you do not accept these terms, please do not access or use this Web Site. By accessing, using, and browsing this Web Site, you accept these terms without limitation or qualification.</p>

        <h4>Intellectual property</h4>
        <p>This Web Site contains text, images/video (including photos, electronic art, animations, graphics), sounds/audio (including voice and music), information and data, communications programs (including Internet links, electronic mail services, and user interfaces), executable code versions of Conscious Crops LLC and third party software applications, and other materials, methods of operation, and Conscious Crops LLC data (collectively, “Conscious Crops LLC Content”) formatted, organized, and collected in a variety of forms, including layouts, pages, screens, directories, and databases. Any unauthorized use of the Conscious Crops LLC Content on this Web Site may violate copyright laws, trademark laws, the laws of privacy and publicity, and communications regulations and statutes. The Conscious Crops LLC Content on this Web Site is either the property of Conscious Crops LLC or is used by Conscious Crops LLC with permission. You may not modify, publish, transmit, participate in the transfer or sale of, create derivative works, or in any way exploit any of the Conscious Crops LLC Content, in whole or in part. You may not upload, post, reproduce, or distribute any Conscious Crops LLC Content unless licensed by Conscious Crops LLC and/or its licensors to do so in a formal license agreement. Nothing contained on this Web Site shall be construed as granting, by implication, estoppel, or otherwise any license or right to use any Conscious Crops LLC Content displayed on this Web Site. Unauthorized use of the Conscious Crops LLC Content by you is strictly prohibited, will violate the legal rights of Conscious Crops LLC and/or its licensors, and may subject you to legal proceedings.</p>
        <br /><p>All of the Conscious Crops LLC trademarks, logos, and service marks (collectively, “Trademarks”) displayed on this Web Site are registered and unregistered Trademarks of Conscious Crops LLC or its licensors. You may not upload, post, reproduce, or distribute any Trademarks unless licensed by Conscious Crops LLC to do so in a formal trademark license agreement. Nothing contained on this Web Site shall be construed as granting, by implication, estoppel, or otherwise any license or right to use any Trademarks displayed on this Web Site without the written permission of Conscious Crops LLC or such third party as may own the trademarks displayed on this Web Site. Unauthorized use of the Trademarks is strictly prohibited, will violate the legal rights of Conscious Crops LLC and/or its licensors, and may subject you to legal proceedings.</p>

        <h4>Warranty disclaimer</h4>
        <p>Your access to, use of, downloading from, and browsing in this Web Site are exclusively at your own risk. Everything on this Web Site is provided on an AS IS basis without warranty of any kind, either expressed or implied. Without limiting the generality of the foregoing, Conscious Crops LLC makes no warranty, representation, or guaranty of merchantability, fitness for a particular purpose, non-infringement, accuracy, correctness, currency, sanitation, availability, reliability, performance, suitability, compatibility, or non-infringement with regard to any aspect of this Web Site. Conscious Crops LLC shall not be liable for any direct, incidental, consequential, indirect, or punitive damages arising out of your access to or use of this Web Site, including any damages of any type caused by viruses that may infect your computer equipment on account of your access to, use of, downloading from, or browsing in this Web Site.</p>
        <br /><p>Conscious Crops LLC will from time to time revise the information, services, and content contained in this Web Site and reserves the right to make such changes without any obligation to notify past, current, or prospective Web Site visitors.</p>

        <h4>Cookies</h4>
        <p>When you visit this Web Site, we may store some information on your computer. This information will be in the form of a “cookie” or similar file and can help us maintain and improve this Web Site in many ways. A “cookie” is a small amount of data that is transferred to your browser by a Web server and can only be read by the server that gave it to you. It functions as your identification card, recording such things as Internet addresses, passwords, and preferences. It cannot be executed as code or deliver viruses. With most Internet browsers, you can erase “cookies” from your computer hard drive, block all “cookies,” or receive a warning before a “cookie” is stored. Please refer to your browser instructions to learn more about these functions.</p>

        <h4>Privacy policy</h4>
        <p>Conscious Crops LLC, Inc. respects the privacy of every individual who visits this Web Site. In general, you can visit Conscious Crops LLC on the Web without telling us who you are or revealing any information about yourself. Conscious Crops LLC will not collect any personally identifiable information about you (e.g., your name, address, telephone number, or e-mail address) by means of this Web Site unless you have provided such personal data to us voluntarily. If you do not want your personal data collected, please do not submit it to us.</p>
        <br /><p>When you engage in certain activities on this Web Site, such as requesting information, electronic contracting, downloading software, or registering software, Conscious Crops LLC may ask you to provide certain information about yourself by filling out and submitting an online form. It is completely optional for you to engage in these activities. If you elect to engage in these activities, however, Conscious Crops LLC may require that you provide your name, mailing address, e-mail address, and other personal identifying information.</p>
        <br /><br /><p>In some cases, Conscious Crops LLC may automatically (i.e., not via registration or online form) collect technical information when you connect to this Web Site that is not personally identifiable; examples of this type of information include the type of Internet browser you are using, the type of computer operating system you are using and the domain name of the web site from which you linked to this Web Site. Conscious Crops LLC recognizes and appreciates the importance of responsible use of this information. Conscious Crops LLC collects this information in order to record and support your participation in the activities you select.</p>
        <br /><br /><p>Conscious Crops LLC also uses information that you provide as part of our effort to keep you informed about product upgrades, special offers, and other Conscious Crops LLC products and services. Conscious Crops LLC does not share any information with third-party marketers.</p>
        <br /><br /><p>All information collected is for Conscious Crops LLC internal use only.</p>
      </div>
      <Footer />
    </PrivacyWrapper>
  )
}

const PrivacyWrapper = styled.div`

.container {
  padding: 4rem 8rem;

  h4 {
    margin-top: 2rem;
    margin-bottom: 0.6rem;
    font-size: 1.8rem;
  }

  h2 {
    font-size: 2.6rem;
  }

  p {
    font-family: 'Lato';
    font-size: 1.4rem;
    text-align: justify;
  }
}
`;
