// React
import React from 'react';
import styled from "styled-components";
// Componentes
import Home from '../components/Home/Home';
import Challenge from '../components/Home/Challenge';
import Goals from '../components/Home/Goals';
import GetInvolved from '../components/Home/GetInvolved';
import Footer from '../components/Footer';

export default function LandingPage() {
  return (
    <LandingWrapper>
      <Home />
      <Challenge />
      <Goals />
      <GetInvolved />
      <Footer />
    </LandingWrapper>
  )
}

const LandingWrapper = styled.div`

`;
