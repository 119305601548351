import React, { useState } from "react";
import styled from "styled-components";
// Components
// import { Link } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import DrawerNav from "../components/DrawerNav";

export default function Navbar({ onHome }) {
  const [navOpen, setNavOpen] = useState(false);

  return (
    <NavbarWrapper>
      <DrawerNav open={navOpen} closeNav={() => setNavOpen(false)} />
      <nav>
        {onHome ? (
          <div className="dektopContainer">
            <Link to="/">
              <img
                src={require("../assets/images/logo.svg")}
                alt="conscious crops"
              />
            </Link>
            <Link to="/#challenge">Challenge</Link>
            <Link to="/#goals">Goals</Link>
            <Link to="/about-us">About us</Link>
            <Link to="/contact">Get Involved</Link>
            <Link to='/login'>
              <button>
                Login
              </button>
            </Link>
          </div>
        ) : (
            <div className="dektopContainer">
              <Link to="/">
                <img
                  src={require("../assets/images/logo.svg")}
                  alt="conscious crops"
                />
              </Link>
              <Link to='/login'>
                <button>
                  Login
              </button>
              </Link>
            </div>
          )}
        <div className="mobileContainer">
          <Link to="/">
            <img
              className="logo"
              src={require("../assets/images/logo_text.png")}
              alt="conscious crops"
            />
          </Link>
          <img
            onClick={() => setNavOpen(true)}
            className="menu"
            src={require("../assets/images/menu.png")}
            alt="menu"
          />
        </div>
      </nav>
    </NavbarWrapper>
  );
}

const NavbarWrapper = styled.section`
  position: relative;
  z-index: 2;

  nav {
    margin: 0 auto;
    margin-top: 2rem;
    padding: 1rem 8rem 1rem 3rem;
    width: calc(100% - 4rem);
    background: #e6ddc9;
    font-family: "Lato";
    font-size: 1.6rem;
    font-weight: 600;

    @media (max-width: 700px) {
      padding: 3rem 3rem;
      margin: 0;
      width: 100%;
    }

    .dektopContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 700px) {
        display: none;
      }

      img {
        height: 4.4rem;
      }

      a {
        transition: 0.2s;

        &:hover {
          opacity: 0.7;
        }
      }

      button {
        background: #2f2c26;
        color: #ffffff;
        border: none;
        font-family: "Lato";
        padding: 1rem 2rem;
        font-size: 1.4rem;
        cursor: pointer;
        transition: 0.2s;

        &:hover {
          opacity: 0.7;
        }
      }
    }

    .mobileContainer {
      display: none;

      @media (max-width: 700px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .logo {
        height: 1.8rem;
      }

      .menu {
        height: 1.8rem;
      }
    }
  }
`;
