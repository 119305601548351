import React, { useContext } from 'react';
import styled from "styled-components";
// Components
import { Link } from "react-router-dom";
// Context
import { ContentContext } from '../lib/Content';

export default function Footer() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  const { content } = useContext(ContentContext);
  const footer = content.footer;

  return (
    <FooterWrapper>
      <div className="back-to-top-container">
        <figure onClick={scrollToTop}>
          <img src={require('../assets/images/back-to-top.png')} alt="back to top" />
          <p>Back to top</p>
        </figure>
      </div>
      <div className="footer-container">
        <div className="info">
          <p className="contact">Contact us</p>
          <a className="mail" href={`mailto:${footer.email}`}>{footer.email}</a>
          <p className="address">{footer.address}</p>
          <div className="linkedin">
            <p>Follow us:</p>
            <a href="https://www.linkedin.com/company/conscious-crops/about/" target="_blank" rel="noopener noreferrer">
              <img src={require('../assets/images/linkedin.png')} alt="linkedin" />
            </a>
          </div>
          <p className="copyDesktop">© 2020 Conscious Crops LLC All Rights Reserved. Read our <Link className="link" to="/privacy">Privacy Policy & Disclaimer</Link></p>
        </div>
        <img className="logo" src={require('../assets/images/logo_footer.svg')} alt="conscious crops" />
        <p className="copyMobile">© 2020 Conscious Crops LLC All Rights Reserved. Read our <Link className="link" to="/privacy">Privacy Policy & Disclaimer</Link></p>
      </div>
    </FooterWrapper>
  )
}

const FooterWrapper = styled.footer`
  background: #E4E0D4;
  margin-top: -1rem;
  z-index: 0;
  position: relative;
  font-family: 'Lato';

  .footer-container {
    padding: 2rem 8rem 4rem 8rem;
    display: flex; 
    justify-content: space-between;
    align-items: flex-end;

    @media(max-width: 800px) {
      flex-direction: column;
      align-items: center;
    }

    img.logo {
      width: 22rem;

      @media(max-width: 800px) {
        margin-top: 6rem;
        margin-bottom: 2rem;
      }
    }

    .copyMobile {
      width: 110%;
      display: none;
      font-size: 1.4rem;
      margin-top: 2rem;
      text-align: center;

      @media(max-width: 800px) {
        display: block;
      }
    }

    .info {
      .contact {
        margin-bottom: 1rem;
      }

      .mail {
        font-size: 2.9rem;
        font-weight: bolder;
        font-family: 'Tinos';

        @media(max-width: 400px) {
          font-size: 3rem;
        }
      }

      .address {
        font-weight: bolder;
        font-size: 1.4rem;
        margin-top: 0.8rem;
      }

      .linkedin {
        margin-top: 2rem;
        display: flex;
        align-items: center;
        font-size: 1.2rem;
        font-weight: bolder;

        a {
          transition: 0.3s;
          margin-left: 0.7rem;

          &:hover {
            opacity: 0.7;
          }

          img {
            width: 2.2rem;
          }
        }
      }

      .copyDesktop {
        font-size: 1.1rem;
        margin-top: 1.2rem;
        font-weight: bolder;

        @media(max-width: 800px) {
          display: none;
        }
      }
    }

    .link {
      border-bottom: 1px solid black;
      transition: 0.2s;

      &:hover {
        opacity: 0.7;
      }
    }
  }
  
  .back-to-top-container {  
    padding: 2rem 3rem; 
    display: flex;
    justify-content: flex-end;

    figure {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-family: 'Lato';
      font-size: 1.1rem;
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        opacity: 0.7;
      }

      img {
        width: 2.5rem;
      }
    }
  }
`;
