// React
import React from "react";
import styled from "styled-components";
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

export default function PageNotFound() {
    return (
        <div>
            <Navbar onHome />
            <PageNotFoundWrapper>
                <h1>
                    404
                </h1>
                <p>Page not found</p>
            </PageNotFoundWrapper>
            <Footer />
        </div> 
    );
}

const PageNotFoundWrapper = styled.div`
    background: #f8f6f0;
    width: 100%;
    height: 100%;
    padding: 10% 50px;
    z-index: 50;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
        font-size: 15rem;
        color: rgba(181, 166, 121, 0.77);
        text-shadow: 2px 3px 5px rgba(107, 80, 0, 0.37);
        letter-spacing: 0.5rem;
    }
    p {
        width: 30%;
        font-family: 'Lato';
        font-size: 1.3rem;
        line-height: 2.8rem;
        font-weight: 600;
        text-align: center;
        @media(max-width: 800px) {
            width: 70%;
        }
    }
`;
