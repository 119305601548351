import { db } from "../lib/Firebase";

class ContactFormService {
  sendForm(contact) {
    const prospectsRef = db.collection('prospects');
    return prospectsRef.where('email', '==', contact.email).get().then(snapshot => {
      if (snapshot.size < 1) {
        return prospectsRef.add(contact);
      }
      return false;
    })
  }
}

export default ContactFormService;