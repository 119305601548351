import React, { useEffect } from 'react';
import { Routes } from './Routes';
import { loadReCaptcha } from 'react-recaptcha-v3'
import { ContentProvider } from './lib/Content';

function App() {
  useEffect(() => {
    loadReCaptcha('6Ldb0-wUAAAAABahXVzaj_9S7QBmLQxML-e3Cp2Z');
  }, []);

  return (
    <ContentProvider>
        <Routes />
    </ContentProvider>
    // <>
    // </>
  );
}

export default App;
