// React
import React, { useState, useContext } from 'react';
import styled from "styled-components";
import { Redirect } from "react-router";
import { useForm } from "react-hook-form";
import { emailPattern } from '../lib/Patterns';
import { AuthContext } from "../lib/Auth";
import AuthService from "../services/AuthService";

// Componentes
import NavbarInternal from '../components/NavbarInternal';
import FooterInternal from '../components/FooterInternal';

export function ResetPassword(props) {
    const { setResetFalse, showNotify } = props;
    const { handleSubmit, register, errors, reset } = useForm();
    const authService = new AuthService();
    const sendResetPasswordEmail = ({ email }) => {
        authService.sendResetPasswordEmail(email).then(() => {
            showNotify();
            setResetFalse();
        })
    }

    return (
        <form className="card" onSubmit={handleSubmit(sendResetPasswordEmail)}>
        <h2>Reset password</h2>
        <p className="subtitle">Provide your email, we will send you an email so you can set a new your password</p>

        {/* EMAIL */}
        <div className="input-group">
          <label htmlFor="email">Email</label>
          <input placeholder="Email" type="text" id="email" name="email" ref={register({ required: 'Required', pattern: { value: emailPattern, message: 'Invalid Email' } })} />
          <p className="errorMessage">{errors.email && errors.email.message}</p>
        </div>

        {/* SUBMIT */}
        <div className="button-container">
          <p onClick={setResetFalse}>Go back</p>
          <button>Send</button>
        </div>
      </form>
    )

}

export default function LoginPage({ history }) {
  const { handleSubmit, register, errors, reset } = useForm();
  const [resetPassword, setResetPassword] = useState(false);
  const [showNotify, setShowNotify] = useState(false);
  const { currentUser } = useContext(AuthContext);

  const authService = new AuthService();

  if (currentUser) {
    return <Redirect to="/dashboard" />;
  }

  const onSubmit = values => {
    authService.login(values.email, values.password).then(res => {
      reset();
      history.push('/dashboard');
    }).catch(err => {
      setShowNotify('An error has ocurred');
      setTimeout(() => setShowNotify(false), 5000);
    });
  };

  return (
    <LoginWrapper>
      <NavbarInternal />
      <div className="container">
        {!resetPassword ?
                <form className="card" onSubmit={handleSubmit(onSubmit)}>
                <h2>Welcome back!</h2>
                <p className="subtitle">Log in into your account</p>
      
                {/* EMAIL */}
                <div className="input-group">
                  <label htmlFor="email">Email</label>
                  <input placeholder="Email" type="text" id="email" name="email" ref={register({ required: 'Required', pattern: { value: emailPattern, message: 'Invalid Email' } })} />
                  <p className="errorMessage">{errors.email && errors.email.message}</p>
                </div>
      
                {/* PASSWORD */}
                <div className="input-group">
                  <label htmlFor="password">Password</label>
                  <input placeholder="Password" type="password" name="password" id="password" ref={register({ required: 'Required' })} />
                  <p className="errorMessage">{errors.password && errors.password.message}</p>
                </div>
      
                {/* SUBMIT */}
                <div className="button-container">
                  <p onClick={() => setResetPassword(true)}>Forgot password?</p>
                  <button>Sign in</button>
                </div>
              </form>
        :
            <ResetPassword 
                setResetFalse={ () => setResetPassword(false) } 
                showNotify={ () => {setShowNotify('Email sent!'); setTimeout(() => setShowNotify(false), 5000);}}
            />
        }
      </div>

      <div className={`notify ${showNotify ? 'show' : ''}`}>
        {showNotify}
      </div>
      <FooterInternal />
    </LoginWrapper>
  )
}

const LoginWrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;

  .container {
    flex: 1;
    background: url(${require('../assets/images/background_login.jpg')});
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .card {
    margin: 2rem;
    width: 30rem;
    background: white;
    padding: 2rem;

    h2 {
      text-align: center;
      font-size: 2.6rem;
    }

    p.subtitle {
      text-align: center;
      margin-bottom: 2rem;
      font-family: 'Lato';
      font-size: 1.3rem;
    }

    .button-container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        font-size: 1.3rem;
        transition: 0.3s;
        cursor: pointer;

        &:hover {
          opacity: 0.7;
        }
      }
    }
  }

  .input-group {
    width: 100%;
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
      margin-bottom: 2rem;
    }

    label {
      font-size: 1.4rem;
      font-weight: bolder;
      margin-bottom: 0.8rem;
    }

    input {
      width: 100%;
      background: #F5F1E8;
      padding: 1rem 0.7rem;
      border: none;
      border-bottom: 2px solid black;
      font-family: 'Lato';
      font-size: 1.2rem;
    }

    .errorMessage {
      font-size: 1rem;
      margin-top: 0.4rem;
      color: red;
    }
  }

  button {
    background: #2F2C26;
    color: #ffffff;
    border: none;
    font-family: 'Lato';
    padding: 1rem 2rem;
    font-size: 1.4rem;
    cursor: pointer;
    transition: 0.2s;

    &:disabled {
      background: #bfbfbe;
      cursor: not-allowed;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  .notify {
    position: fixed;
    bottom: 4rem;
    right: 4rem;
    background: #E6DDC9;
    padding: 2rem 4rem;
    border-bottom: 2px solid black;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    transform: scale(0);
    transition: 0.4s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    z-index: 90;

    &.show {
      transform: scale(1);
    }
  }
`;
