import React, { useContext } from 'react';
import styled from "styled-components";
// Context
import { ContentContext } from '../../lib/Content';

export default function Challenge() {
  const { content } = useContext(ContentContext);
  const origin = content.origin;
  return (
    <ChallengeWrapper bottomImage={origin.bottomImage}>
      <div id="challenge" className="text-orange">
        <div className="text-info">
          <p className="title">{origin.title}</p>
          <p className="text">
            {origin.textUnderTitle}
          </p>
        </div>
        <img src={origin.imageBesideTitle.url} alt={origin.imageBesideTitle.name} />
      </div>
      <div className="second-section">
        <div className="blue-box">
          <div className="left">
            <p className="title">{origin.yellowBoxTitle}</p>
          </div>
          <div className="right">
            <p className="text bold">
              {origin.yellowBoxText}
            </p>
          </div>
        </div>
      </div>
      <div className="farm-box">
        <p>
          {origin.bottomText}
        </p>
      </div>
    </ChallengeWrapper>
  )
}

const ChallengeWrapper = styled.section`
  background: #F8F6F0;

  .second-section {
    padding: 4rem 10rem 0 10rem;

    @media(max-width: 500px) {
      padding: 2rem 2rem 0 2rem;
    }

    .blue-box {
      background: #F3C256;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      color: white;
      padding: 3rem 8rem 8rem;

      @media(max-width: 800px) {
        flex-direction: column;
        padding: 3rem 5rem 8rem;
      }

      p.title {
        font-size: 3rem;
        font-weight: bolder;
        margin-bottom: 1.5rem;
      }

      p.text {
        font-family: 'Lato';
        font-size: 1.7rem;
        line-height: 2.4rem;

        &.bold {
          font-weight: bolder;
        }
      }

      .left {
        width: 100%;
        padding-right: 10%;

        @media(max-width: 1000px) {
          padding-right: 15%;
        } 
      }

      .right {
        width: 80%;

        @media(max-width: 700px) {
          width: 95%;
        }
      }
    }
  }

  .farm-box {
    width: 100%;
    background-image: url(${props => props.bottomImage.url});
    background-size: cover;
    padding: 26rem 0 4rem 0;
    margin-top: -3rem;

    p {
      color: white;
      width: 40%;
      text-align: center;
      margin: 0 auto;
      font-family: 'Lato';
      font-weight: 900;

      @media(max-width: 500px) {
        width: 80%;
      }
    }
  }

  .text-orange {
    padding: 8rem 10rem 2rem 10rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media(max-width: 800px) {
      flex-direction: column;
    } 

    @media(max-width: 500px) {
      padding: 4rem 4rem 0 4rem;
    }

    .text-info {
      width: 31%;

      @media(max-width: 1000px) {
        width: 40%;
      }

      @media(max-width: 800px) {
        width: 100%;
        margin-bottom: 3rem;
      }

      p.title {
        font-weight: bolder;
        font-size: 3rem;
      }

      p.text {
        font-size: 1.5rem;
        margin-top: 2rem;
        font-family: 'Lato';
        line-height: 2.4rem;

        &:nth-child(2) {
          font-weight: bold;
        }
      }
    }

    img {
      width: 40%;
      margin-right: 15%;

      @media(max-width: 1000px) {
        margin-right: 8%;
      } 

      @media(max-width: 800px) {
        width: 20rem;
      }
    }
  }
`;
