// React
import React, { useEffect } from 'react';
import styled from "styled-components";
// Componentes
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import ContactForm from '../components/ContactForm';

export default function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <ContactWrapper>
      <Navbar onHome={false} />
      <div className="welcome">
        <p className="title">Welcome to Conscious Crops</p>
        <div className="info">
          <p>Thank you for your interest in our project.</p>
          <p>For more information* please fill out this form and we will contact you to get started. <span>*As required by SEC</span></p>
        </div>
      </div>
      <ContactForm />
      <Footer />
    </ContactWrapper>
  )
}

const ContactWrapper = styled.div`

  .welcome {
    background: #9CB474;
    width: 85%;
    padding: 3rem 5rem;
    color: white;
    margin: 0 auto;
    margin-top: 4rem;

    .title {
      width: 35%;
      font-size: 3.5rem;
      font-weight: bolder;

      @media(max-width: 550px) {
        margin-bottom: 2rem;
      }
    }

    .info {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-top: -3rem;

      @media(max-width: 550px) {
        margin-top: 0;
        flex-direction: column;
      }

      p {
        width: 50%;
        font-family: 'Lato';
        font-size: 1.7rem;

        @media(max-width: 550px) {
          width: 100%;

          &:not(:last-child) {
            margin-bottom: 2rem;
          }
        }

        &:nth-child(2) {
          display: flex;
          flex-direction: column;
          padding-left: 10%;

          @media(max-width: 550px) {
            padding-left: 0;
          }

          span {
            font-size: 1rem;
            margin-top: 0.6rem;
          }
        }
      }
    }
  }
`;
