import React, { useContext } from 'react';
import styled from "styled-components";
// Components
import Navbar from '../Navbar';
// Context
import { ContentContext } from '../../lib/Content';

export default function Home() {
  const { content } = useContext(ContentContext);
  const landingOurBackground = content.landingOurBackground;

  return (
    <HomeWrapper backgroundImage={landingOurBackground.backgroundImage}>
      <Navbar onHome={true} />
      <div className="overlay"></div>
      <div className="info-text">
        <p className="title">{landingOurBackground.title}</p>
      </div>
    </HomeWrapper>
  )
}

const HomeWrapper = styled.section`
  height: 100vh;
  width: 100%;
  background-image: linear-gradient(transparent 30%, rgba(0, 0, 0, 0.1)), url(${props => props.backgroundImage.url});
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  position: relative;

  @media(max-width: 700px) {
    height: unset;
  }

  .overlay {
    background-image: linear-gradient(transparent 40%, rgba(0, 0, 0, 0.9));
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .info-text {
    width: 100%;
    bottom: 0;
    padding: 9rem 10%;
    display: flex;
    align-items: center;
    position: relative;
    color: white;

    @media(max-width: 700px) {
      flex-direction: column;
      padding: 50% 10% 3rem 10%;
    }

    p.title {
      font-size: 4rem;
      flex: 2;
      font-weight: bolder;
      max-width: 80%;
    }
  }
`;
