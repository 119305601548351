import React, { useContext } from 'react';
import styled from "styled-components";
// Components
import Navbar from '../Navbar';
// Context
import { ContentContext } from '../../lib/Content';

export default function Home() {
  const { content } = useContext(ContentContext);
  const landingFurtherInformation = content.landingFurtherInformation;
  return (
    <HomeWrapper backgroundImage={landingFurtherInformation.backgroundImage}>
      <Navbar onHome={true} />
      <div className="overlay"></div>
      <div className="info-text">
        <p className="title">{landingFurtherInformation.title}</p>
      </div>
    </HomeWrapper>
  )
}

const HomeWrapper = styled.section`
  height: 65vh;
  width: 100%;
  background-image: linear-gradient(transparent 30%, rgba(255, 255, 255, 0.3)), url(${props => props.backgroundImage.url});
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  position: relative;

  @media(max-width: 700px) {
    height: unset;
  }

  .overlay {
    background-image: linear-gradient(transparent 40%, rgba(255, 255, 255, 0.9));
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .info-text {
    width: 100%;
    bottom: 0;
    padding: 6rem 10%;
    display: flex;
    align-items: center;
    position: relative;
    color: white;

    @media(max-width: 700px) {
      flex-direction: column;
      padding: 50% 10% 3rem 10%;
    }

    p.title {
      font-size: 4rem;
      flex: 3;
      font-weight: bolder;
      color: black;
      max-width: 80%;
    }

    p.text {
      flex: 2;
      font-size: 2rem;
      font-family: 'Lato';
      font-weight: bolder;

      span {
        padding-left: 2rem;
        padding-right: 8rem;
        display: block;

        @media(max-width: 700px) {
          padding: 0;
          margin-top: 2rem;
        }
      }
    }
  }
`;
