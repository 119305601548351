import React, { useContext } from "react";
import { Switch, Route } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import ContactPage from "./pages/ContactPage";
import PrivacyPage from "./pages/PrivacyPage";
import OurBackgroundPage from "./pages/OurBackgroundPage";
import SecondLanding from "./pages/SecondLanding";
import LoginPage from "./pages/LoginPage";
import { AuthProvider } from "./lib/Auth";
import DashboardPage from "./pages/DashboardPage";
import PrivateRoute from "./lib/PrivateRoute";
import { ContentContext } from "./lib/Content";
import { createBrowserHistory } from "history";
import SetPasswordPage from "./pages/SetPasswordPage";
import pageNotFound from "./pages/404";

const browserHistory = createBrowserHistory();

browserHistory.listen((location) => {
  const { hash } = location;
  if (hash !== "") {
    // Push onto callback queue so it runs after the DOM is updated,
    // this is required when navigating from a different page so that
    // the element is rendered on the page before trying to getElementById.
    setTimeout(() => {
      const id = hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView();
      }
    }, 0);
  }
});

export const Routes = (props) => {
  const { content } = useContext(ContentContext);
  if (content) {
    return (
      <AuthProvider>
        <Switch location={props.location}>
          <Route exact path={["/"]} component={LandingPage} />
          <Route exact path={["/about-us"]} component={OurBackgroundPage} />
          <Route exact path={["/landing2"]} component={SecondLanding} />
          <Route exact path={["/contact"]} component={ContactPage} />
          <Route exact path={["/privacy"]} component={PrivacyPage} />
          <Route exact path={["/login"]} component={LoginPage} />
          <Route exact path={["/set-password"]} component={SetPasswordPage} />
          <PrivateRoute exact path={["/dashboard"]} component={DashboardPage} />
          <Route exact path={["*"]} component={pageNotFound} />
        </Switch>
      </AuthProvider>
    );
  } else {
    return (
      <div
        className="animated fadeIn"
        style={{
          display: "flex",
          height: "96vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={require("./assets/images/logo.svg")} alt="Consciour Crops" />
      </div>
    );
  }
};
