import React from 'react';
import styled from "styled-components";
// Components
import { Link } from "react-router-dom";

export default function FooterInternal({ user }) {

  return (
    <FooterInternalWrapper>
      <p>© 2020 Conscious Crops LLC All Rights Reserved. Read our <Link className="link" to="/privacy">Privacy Policy & Disclaimer</Link></p>
    </FooterInternalWrapper>
  )
}

const FooterInternalWrapper = styled.footer`
  position: relative;
  z-index: 0;
  background: #E4E0D4;
  padding: 2rem 3rem;
  font-size: 1.2rem;
  font-family: 'Lato';

  @media(max-width: 480px) {
    text-align: center;
  }
  
  .link {
    border-bottom: 1px solid black;
    transition: 0.2s;

    &:hover {
      opacity: 0.7;
    }
  }
`;
