import React, { useContext } from 'react';
import styled from "styled-components";
// Context
import { ContentContext } from '../../lib/Content';

export default function Goals() {
    const { content } = useContext(ContentContext);
    const goals = content.goals;
  return (
    <GoalsWrapper>
      <div id="goals" className="intro">
        <h2>{goals.title}</h2>
        <p className="subtitle">{goals.subtitle}</p>
      </div>
      <div className="goals-container">
        <div className="goal orange">
          <img src={goals.firstGoalImage.url} alt={goals.firstGoalImage.name} />
          <div className="info">
            <p className="title">{goals.firstGoalTitle}</p>
            <p className="text">{goals.firstGoalText}</p>
          </div>
        </div>
        <div className="goal water">
        <img src={goals.secondGoalImage.url} alt={goals.secondGoalImage.name} />
          <div className="info">
            <p className="title">{goals.secondGoalTitle}</p>
            <p className="text">{goals.secondGoalText}</p>
          </div>
        </div>
        <div className="goal coins">
        <img src={goals.thirdGoalImage.url} alt={goals.thirdGoalImage.name} />
          <div className="info">
            <p className="title">{goals.thirdGoalTitle}</p>
            <p className="text">{goals.thirdGoalText}</p>
          </div>
        </div>
      </div>
    </GoalsWrapper>
  )
}

const GoalsWrapper = styled.section`
  background: #F8F6F0;
  padding: 6rem 2rem 0;

  .goals-container {
    margin-top: 4rem;
    display: flex;

    @media(max-width: 730px) {
      flex-direction: column;
      margin-top: 10rem;
    }

    .goal {
      width: 33.33%;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      @media(max-width: 730px) {
        width: 100%;

        &:not(:last-child) {
          margin-bottom: 4rem;
        }
      }

      .info {
        width: 90%;
        position: absolute;
        top: 64%;
        padding-bottom: 2rem;
        padding-left: 1.5rem;

        @media(max-width: 900px) {
          top: 56%;
        }

        p {
          width: 100%;
          text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);

          &.title {
            font-size: 2.3rem;
            padding-right: 1rem;
            font-weight: bolder;
          }

          &.text {
            width: 80%;
            font-size: 1.7rem;
            margin-top: 1rem;
            font-family: 'Lato';
            font-weight: bolder;
          }
        }
      }

      &.orange {
        background: #9CB474;
        color: white;

        img {
          width: 85%;
          margin-top: -10%;

          @media(max-width: 730px) {
            width: 60%;
          }

          @media(max-width: 500px) {
            width: 80%;
          }
        }
      }

      &.water {
        background: #E6DDC9;

        img {
          width: 75%;

          @media(max-width: 730px) {
            height: 56rem;
            object-fit: contain;
          }

          @media(max-width: 450px) {
            height: 45rem;
          }

          @media(max-width: 400px) {
            height: 40rem;
          }
        }
      }

      &.coins {
        background: #F3C256;
        color: white;

        img {
          width: 100%;
          margin-top: -10%;

          @media(max-width: 730px) {
            width: 60%;
            margin-top: 0;
          }
        }
      }
    }
  }

  .intro {
    text-align: center;
    color: #2F2C26;

    h2 {
      font-size: 3rem;
      width: 30%;
      margin: 0 auto;

      @media(max-width: 820px) {
        width: 40%;
      }

      @media(max-width: 560px) {
        width: 75%;
      }
    }

    p.subtitle {
      margin-top: 3rem;
      font-family: 'Lato';
    }
  }
`;
