import React, { useContext } from 'react';
import styled from "styled-components";
import { AuthContext } from "../lib/Auth";
// Components
import { Link } from "react-router-dom";
import AuthService from '../services/AuthService';

export default function NavbarInternal() {
  const authService = new AuthService();
  const { currentUser } = useContext(AuthContext);
    console.log(currentUser)
  const logout = () => {
    authService.logout();
  };

  return (
    <NavbarInternalWrapper>
      <nav>
        <div className="dektopContainer">
          <Link to='/'>
            <img src={require('../assets/images/logo.svg')} alt="conscious crops" />
          </Link>
          {currentUser ? (
            <div className="userInfo">
              <p>Hello, {currentUser.displayName}</p>
              <button onClick={logout}>
                Log Out
            </button>
            </div>
          ) : null}
        </div>
        <div className="mobileContainer">
          <Link to='/'>
            <img onClick={logout} className="logo" src={require('../assets/images/logo_text.png')} alt="conscious crops" />
          </Link>
          {currentUser ? (
            <div className="userInfo">
              <p>Hello, {'Henry'}</p>
              <img src={require('../assets/images/logout.png')} alt="logout" />
            </div>
          ) : null}
        </div>
      </nav>
    </NavbarInternalWrapper>
  )
}

const NavbarInternalWrapper = styled.section`
  position: relative;
  z-index: 2;

  nav {
    margin: 0 auto;
    padding: 1rem 3rem;
    width: 100%;
    background: #E6DDC9;
    font-family: 'Lato';
    font-size: 1.6rem;
    font-weight: 600;

    @media(max-width: 700px) {
      padding: 3rem 3rem;
      margin: 0;
      width: 100%;
    }

    .dektopContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media(max-width: 700px) {
        display: none;
      }

      .userInfo {
        display: flex;
        align-items: center;

        p {
          margin-right: 2rem;
        }
      }

      img {
        height: 4.4rem;
      }
      
      a {
        transition: 0.2s;

        &:hover {
          opacity: 0.7;
        }
      }

      button {
        background: #2F2C26;
        color: #ffffff;
        border: none;
        font-family: 'Lato';
        padding: 1rem 2rem;
        font-size: 1.4rem;
        cursor: pointer;
        transition: 0.2s;

        &:hover {
          opacity: 0.7;
        }
      }
    }

    .mobileContainer {
      display: none;

      .userInfo {
        display: flex;
        align-items: center;

        p {
          font-size: 1.4rem;
          margin-right: 2rem;
          padding-left: 0.5rem;
        }

        img {
          width: 3rem;
        }
      }

      @media(max-width: 700px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .logo {
        height: 1.2rem;
      }

      .menu {
        height: 1.8rem;
      }

    }
  }
`;
