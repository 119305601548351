// React
import React, { useState, useEffect } from 'react';
import styled from "styled-components";
// Componentes
import NavbarInternal from '../components/NavbarInternal';
import FooterInternal from '../components/FooterInternal';
// firebase
import { db } from "../lib/Firebase";

function Loading() {
    return (
        <div
          className="animated fadeIn"
          style={{
            display: "flex",
            height: "96vh",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={require("../assets/images/logo.svg")} alt="Consciour Crops" />
        </div>
      );
}

export default function DashboardPage() {
    const [loading, setLoading] = useState(true);
    const [pods, setPods] = useState([]);
    const [podName, setPodName] = useState('');
    // const items = [
    //   { name: '1st quarter 2020 update', date: '04/05/2020', file: '#' },
    //   { name: '1st quarter 2020 update', date: '04/05/2020', file: '#' },
    //   { name: '1st quarter 2020 update', date: '04/05/2020', file: '#' },
    //   { name: '1st quarter 2020 update', date: '04/05/2020', file: '#' },
    //   { name: '1st quarter 2020 update', date: '04/05/2020', file: '#' },
    // ];

    async function getPods() {
        db.collection('pods').where('deleted', '==', false).get().then(podsSnapshot => {
            db.collection('podName').doc('PVGbOQxTVQNMG2fSL0La').get().then(podNameSnapshot => {
                const podName = podNameSnapshot.data().podName
                const pods = podsSnapshot.docs.map(doc => doc.data());
                console.log(podName)
                setPodName(podName);
                setPods(pods);
                setLoading(false);
            })
        }).catch(err => {
            console.error(err)
            setLoading(false);
        })
    }

    useEffect(() => {
        getPods()
    }, [])

  return ( loading ?
    <Loading/>
    :
    <DashboardWrapper>
      <NavbarInternal />
      <div className="container">
        <div className="info">
          <p>Your updates on:</p>
          <h2>{podName}</h2>
        </div>
        <div className="files-container">
          <img className="banner" src={require('../assets/images/background_dashboard.jpg')} alt="background" />
          {pods && pods.map(item => (
            <div key={item} className="file">
              <img src={require('../assets/images/pdf_icon.svg')} alt="pdf" />
              <div className="name">
                <p className="titleFile">Name:</p>
                <p className="data">{item.title}</p>
              </div>
              <div className="date">
                <p className="titleFile">Date:</p>
                <p className="data">{item.date}</p>
              </div>
              <a className="buttonContainer" href={item.file.url} title={item.file.name} target='_blank' rel='noopener noreferrer'>
                <button>
                  <p>Download</p>
                  <img src={require('../assets/images/save.png')} alt="save" />
                </button>
                <img className="saveMobile" src={require('../assets/images/save_black.png')} alt="save" />
              </a>
            </div>
          ))}
        </div>
      </div>
      <FooterInternal />
    </DashboardWrapper>
  )
}

const DashboardWrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;

  .container {
    flex: 1;
    display: flex;
    margin: 2rem 0;

    @media(max-width: 770px) {
      flex-direction: column;
    }

    .info {
      padding: 2rem 5rem;
      border-right: 1px solid #E4E0D4;
      width: 30%;

      @media(max-width: 770px) {
        width: 100%;
        border: none;
      }
    }

    .files-container {
      padding: 2rem 5rem;
      width: 70%;

      @media(max-width: 770px) {
        width: 100%;
        padding: 2rem 1.5rem;
      }

      img.banner {
        width: 100%;
        margin-bottom: 2rem;
      }

      .file {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 2rem;
        border: 1px solid #E4E0D4;
        font-family: 'Lato';
        
        &:not(:last-child) {
          margin-bottom: 1rem;
        }

        img {
          width: 3.5rem;
          margin-right: 0.5rem;
        }

        .titleFile {
          font-size: 1.2rem;

          @media(max-width: 770px) {
            font-size: 1rem;
          }
        }

        .data {
          font-weight: bold;
          font-size: 1.4rem;

          @media(max-width: 770px) {
            font-size: 1.%.2rem;
          }
        }

        .buttonContainer {
          .saveMobile {
            display: none; 
            width: 1.5rem;
            margin-left: 1rem;

            @media(max-width: 770px) {
              display: block;
            }
          }
        }

        button {
          background: #2F2C26;
          color: #ffffff;
          border: none;
          font-family: 'Lato';
          padding: 1rem 2rem;
          font-size: 1.4rem;
          cursor: pointer;
          transition: 0.2s;
          display: flex;
          align-items: center;

          @media(max-width: 770px) {
            display: none;
          }

          &:hover {
            opacity: 0.7;
          }

          img {
            width: 1.5rem;
            margin-left: 1.5rem;
          }
        }
      }
    }
  }
`;
