// React
import React, { useState } from "react";
import styled from "styled-components";
import axios from 'axios';
import {
    useLocation
  } from "react-router-dom";

export default function Disclaimer() {
    const [show, setShow] = useState(true);
    let location = useLocation();
    const registerAccess = (hash) => {

        axios.post(
          'https://us-central1-consciouscrops-40375.cloudfunctions.net/prospectAccess',
          {
            data: {
              hash
            }
          }
        ).then(v =>
            console.log('Done', v)
        )
      }

    return (
        <>
            {show ? (
                <DisclaimerWrapper>
                    <p className="text">
                        The link you are accessing is private and intended only for the recipient.
                        Its content may not be disclosed or used by anyone other than the addressee
                        as it contains privileged, confidential or other information of a proprietary
                        and confidential nature. If you are not the intended recipient, please notify
                        us immediately through the email below and delete all information contained
                        herein. Be advised that any review, disclosure, copying, or dissemination of
                        the information contained in this e-communication is prohibited.
                    </p>
                    <a href="mailto:info@consciouscrops.us">info@consciouscrops.us</a>
                    <button onClick={() => { registerAccess(location.search.substring(5)); setShow(false)}}>Accept</button>
                </DisclaimerWrapper>
            ) : null}
        </>
    );
}

const DisclaimerWrapper = styled.div`
    background: #f8f6f0;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p, a {
        width: 30%;
        font-family: 'Lato';
        font-size: 1.3rem;
        line-height: 2.8rem;

        @media(max-width: 800px) {
            width: 70%;
        }
    }

    a {
        font-weight: bolder;
        margin-bottom: 2rem;
    }

    button {
        background: #2F2C26;
        color: #ffffff;
        border: none;
        font-family: 'Lato';
        padding: 1rem 2rem;
        font-size: 1.4rem;
        cursor: pointer;
        transition: 0.2s;

        &:hover {
        opacity: 0.9;
        }

        &:disabled {
        background: #979797;
        cursor: not-allowed;
        }
    }
`;
