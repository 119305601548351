import React, { useContext } from 'react';
import styled from "styled-components";
import { Link } from "react-router-dom";
// Context
import { ContentContext } from '../../lib/Content';

export default function GetInvolved() {
    const { content } = useContext(ContentContext);
    const getInvolved = content.getInvolved;
  return (
    <GetInvolvedWrapper>
      <div id="getInvolved" className="orange-box">
        <div className="info">
          <p className="title">{getInvolved.title}</p>
          <p className="text">{getInvolved.text}</p>
        </div>
        <Link to='/contact' className="button">
          <button>{getInvolved.buttonText}</button>
        </Link>
      </div>

      <div className="forest-container">
        <img className="backtree" src={getInvolved.backgroundImage.url} alt={getInvolved.backgroundImage.name} />
        <img className="tree" src={getInvolved.protrudingImage.url} alt={getInvolved.protrudingImage.name} />
      </div>
    </GetInvolvedWrapper>
  )
}

const GetInvolvedWrapper = styled.section`
  background: #F8F6F0;
  margin-top: 10rem;
  /* overflow: hidden; */

  @media(max-width: 700px) {
    margin-top: 5rem;
  }

  @media(max-width: 550px) {
    overflow: hidden;
  }

  .forest-container {
    /* overflow: hidden; */
    position: relative;
    margin-top: -13rem;

    @media(max-width: 620px) {
      margin-top: -14rem;
    }

    img.backtree {
      width: 100%;
      height: 18rem;
      object-fit: fill;
      
      @media(max-width: 550px) {
        height: 20rem;
        object-fit: cover;
      }
    }

    img.tree {
      width: 51rem;
      top: -130%;
      right: 18%;
      position: absolute;
      z-index: 0;

      @media(min-width: 2100px) {
        width: 50rem;
        top: -117%;
        right: 10%;
      }

      @media(min-width: 2800px) {
        top: -115%;
        right: 6%;
      }

      @media(min-width: 3200px) {
        top: -70%;
        right: 14%;
        width: 44rem;
      }

      @media(max-width: 1000px) {
        width: 46rem;
        top: -110%;
        right: 13%;
      }

      @media(max-width: 850px) {
        top: -94%;
      }

      @media(max-width: 620px) {
        top: -110%;
      }

      @media(max-width: 550px) {
        width: 56rem;
        top: -90%;
        right: 0%;
      }

      @media(max-width: 460px) {
        width: 46rem;
        top: -80%;
        right: -8%;
      }

      @media(max-width: 400px) {
        right: -16%;
      }
    }
  }

  .orange-box {
    background: #F48C43;
    margin: 0 8rem;
    padding: 4rem 7rem 14rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    @media(max-width: 700px) {
      flex-direction: column;
      align-items: flex-start;
      padding: 2rem 3rem 25rem;
    }
    
    @media(max-width: 500px) {
      margin: 0 2rem;
    }

    .info {
      width: 45%;
      color: white;
      z-index: 30;

      @media(max-width: 700px) {
        width: 100%;
        margin-bottom: 2rem;
      }

      p.title {
        font-size: 3.5rem;
        font-weight: bolder;
      }

      p.text {
        margin-top: 1.2rem;
        font-size: 1.8rem;
        font-family: 'Lato';
      }
    }

    .button {
      z-index: 30;

      button {
        background: #2F2C26;
        color: #ffffff;
        border: none;
        font-family: 'Lato';
        padding: 1rem 2rem;
        font-size: 1.4rem;
        cursor: pointer;
        transition: 0.2s;

        &:hover {
          opacity: 0.9;
        }
      }
    }
  }
`;
