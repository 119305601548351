import React from "react";
import styled, { css } from "styled-components";
// Components
import { HashLink as Link } from "react-router-hash-link";

export default function DrawerNav({ open, closeNav }) {
  return (
    <DrawerWrapper open={open}>
      <div className="exit-hamburguer" onClick={() => closeNav()}>
        <div></div>
        <div></div>
      </div>
      <div className="link-container">
        <Link to="/#challenge" className="link" onClick={() => closeNav()}>
          Challenge
        </Link>
        <Link to="/#goals" className="link" onClick={() => closeNav()}>
          Goals
        </Link>
        <Link to="/about-us" className="link" onClick={() => closeNav()}>
          About us
        </Link>
        <Link to="#getInvolved" className="link" onClick={() => closeNav()}>
          Get Involved
        </Link>
        <Link to="/contact" className="link linkContact">
          Contact Us
        </Link>
      </div>
    </DrawerWrapper>
  );
}

const DrawerWrapper = styled.section`
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: #e4e0d4;
  z-index: 100;
  opacity: 1;
  visibility: hidden;
  transition: 0.4s;
  transform: translateX(100%);

  ${(props) =>
    props.open &&
    css`
      transform: translateX(0);
      opacity: 1;
      visibility: visible;
    `}

  .exit-hamburguer {
    position: absolute;
    width: 3.6rem;
    height: 3.1rem;
    top: 3vh;
    right: 5%;

    &:active {
      div {
        background: #8c8c8c;
      }
    }

    div {
      width: 2.6rem;
      height: 0.2rem;
      background: #2f2c26;
      position: absolute;
      top: 50%;
      transition: 0.3s;

      &:nth-child(1) {
        transform: translateY(-50%) rotate(45deg);
      }

      &:nth-child(2) {
        transform: translateY(-50%) rotate(-45deg);
      }
    }
  }

  .link-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    transform: translate(-50%, -50%);
    text-align: center;
    top: 50%;
    left: 50%;
    width: 80%;
    font-family: "Lato";

    .link {
      color: #2f2c26;
      font-size: 3.5rem;
      transition: 0.7s;
      transform: scale(0);
      display: inline-block;

      ${(props) =>
        props.open &&
        css`
          transform: scale(1);
        `}

      &:active {
        opacity: 0.7;
      }

      &:not(:last-child) {
        margin-bottom: 1.8rem;
      }

      &.linkContact {
        background: #2f2c26;
        color: #ffffff;
        border: none;
        padding: 1rem 2rem;
        cursor: pointer;
        transition: 0.2s;
        margin-top: 3rem;

        &:active {
          opacity: 0.7;
        }
      }
    }
  }
`;
