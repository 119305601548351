import { firebaseApp } from "../lib/Firebase";

class AuthService {
  login(email, password) {
    return firebaseApp.auth().signInWithEmailAndPassword(email, password);
  }

  logout() {
    return firebaseApp.auth().signOut();
  }

  sendResetPasswordEmail(email) {
      return firebaseApp.auth().sendPasswordResetEmail(email);
  }
}

export default AuthService;