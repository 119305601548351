import React, { useState, useContext } from 'react';
import styled from "styled-components";
// Components
import Navbar from '../Navbar';
// Context
import { ContentContext } from '../../lib/Content';

export default function Home() {
  const [videoLoaded, setVideoLoaded] = useState(false)
  const { content } = useContext(ContentContext);
    const landing = content.landing;
  return (
    <HomeWrapper videoPreview={landing.videoPreview}>
      <Navbar onHome={true} />
      <video className={`${videoLoaded ? 'loaded' : ''}`} id="video" disablePictureInPicture playsInline autoPlay loop muted onLoadedData={() => {
        const video = document.querySelector('#video');

        function checkLoad() {
          if (video.readyState === 4) {
            setVideoLoaded(true);
          } else {
            setTimeout(checkLoad, 100);
          }
        }

        checkLoad();
      }}>
        {/* <source src={require('../assets/images/back_video.mp4')} /> */}
        <source src={landing.video.url} />
      </video>
      <div className="overlay"></div>
      <div className="info-text">
        {/* <p className="title">Invest in change, <br /> invest in agriculture</p> */}
        <p className="title">{landing.title}</p>
        <p className="text">
          <span>
            {landing.text}
          </span>
        </p>
      </div>
    </HomeWrapper>
  )
}

const HomeWrapper = styled.section`
  height: 100vh;
  width: 100%;
  background-image: linear-gradient(transparent 30%, white), url(${props =>props.videoPreview.url});
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  position: relative;

  @media(max-width: 700px) {
    height: unset;
  }

  video {
    position: absolute;
    height: 100vh;
    width: 100%;
    object-fit: cover;
    z-index: -1;

    &.loaded {
      z-index: 0;
    }
  }

  .overlay {
    background-image: linear-gradient(transparent 40%, rgba(0, 0, 0, 0.9));
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .info-text {
    width: 100%;
    bottom: 0;
    padding: 9rem 10%;
    display: flex;
    align-items: center;
    position: relative;
    color: white;

    @media(max-width: 700px) {
      flex-direction: column;
      padding: 50% 10% 3rem 10%;
    }

    p.title {
      font-size: 6rem;
      flex: 3;
      font-weight: bolder;
    }

    p.text {
      flex: 2;
      font-size: 2rem;
      font-family: 'Lato';
      font-weight: bolder;

      span {
        padding-left: 2rem;
        padding-right: 8rem;
        display: block;

        @media(max-width: 700px) {
          padding: 0;
          margin-top: 2rem;
        }
      }
    }
  }
`;
